<template>
  <q-card>
    <q-card-section v-if="!options.noSearch" class="row">
      <form @submit="handleSearchSubmit" class="col">
        <q-input
            standout="bg-teal text-white"
            :model-value="search"
            :label="$t('Search')"
            :debounce="300"
            @focus="onFocus"
            @blur="onFocusOut"
            @update:model-value="handleSearch"
        />
      </form>

      <div class="col-1 q-items-center q-pt-sm">
        <q-toggle
            standout="bg-teal text-white"
            v-model="ilike"/>
      </div>
    </q-card-section>

    <q-card-section v-if="!hideList" class="q-pa-none border">
      <q-table
          row-key="id"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          :rows-per-page-options="[]"
          table-header-class="d-none"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
              :props="props"
              class="clickable"
              @click="handleItem(props.row)"
          >
            <q-td
                key="image"
                :props="props"
                auto-width
            >
              <img
                  :src="props.row.image ? props.row.image : fallbackImage"
                  style="width: 80px; height: 70px; object-fit: contain;"
                  @error="onImageLoadFailure"
              >
            </q-td>

            <q-td
                key="details"
                :props="props"
                style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
            >
              <div class="text-subtitle1">
                <q-badge>{{ props.row.type }}</q-badge>
                {{ `${props.row.name} (${props.row.id})` }}
              </div>

              <div class="text-caption">
                <strong v-if="props.row.sku">{{ props.row.sku }}</strong><br v-if="props.row.sku"/>
                {{ props.row.barcodes.join(', ') }}
              </div>
            </q-td>

            <q-td style="max-width:100px;">
                  <span v-if="!props.row.items || props.row.items.length === 0 || !props.row.items[0]">
                    <strong>-</strong><br>
                  </span>
              <span v-else>
                    <table class="table table-valign-middle">
                      <tbody>
                        <tr
                            v-for="item in isItems(props.row.items)"
                            :key="item.id"
                        >
                          <td>
                            <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[item.state]}`">
                              {{ $t(stateName[item.state] || item.state) }}
                            </label>
                          </td>

                          <td>
                            <strong>{{ item.count }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>

    <!--      <q-card-actions align="center">-->
    <!--        <q-btn-->
    <!--            color="negative"-->
    <!--            :label="$t(options.noSelect ? 'Close' : 'Cancel')"-->
    <!--            no-caps-->
    <!--            @click="handleClose"-->
    <!--        />-->
    <!--      </q-card-actions>-->
  </q-card>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OffersSearch',
  emits: ['selected', 'search'],
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      isLoading: false,
      items: [],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      filter: '',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received',
        defected: 'Defected',
      },
      search: null,
      ilike: true,
      hideList: false
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ]),
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'updateTerminalSettings'
    ]),
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    isItems (items) {
      return items.filter(e => (e.state === 'normal' || e.state === 'new')).sort((a, b) => {
        let fa = a.state.toLowerCase(),
            fb = b.state.toLowerCase()
        if (fa[0] < fb[0]) {
          return -1
        }
        if (fa[0] > fb[0]) {
          return 1
        }
        return 0
      })
    },
    handleSearchSubmit () {
      this.hideList = false

      return this.onRequest({ pagination: { page: 1 } })
    },
    handleSearch (search) {
      this.items = []
      this.hideList = false
      this.search = search
      return this.onRequest({ pagination: { page: 1 } })
    },
    onFocus () {
      this.isFocused = true

      this.updateTerminalSettings({ catchAll: false })
    },
    onFocusOut () {
      this.isFocused = false

      this.updateTerminalSettings({ catchAll: true })
    },
    onRequest (data = { pagination: {} }) {
      this.$emit('search', this.search)

      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      if (!query.filter) {
        query.filter = []
      }

      if (this.options.filter) {
        query.filter = _.cloneDeep(this.options.filter)
      }

      let search = this.search

      if (this.ilike) {
        query.filter.push(
            {
              type: 'orx',
              conditions: [
                {
                  field: 'doc::text',
                  type: 'ilike',
                  value: search + `%`
                },
                {
                  field: 'doc::text',
                  type: 'ilike',
                  value: `%` + search
                },
                {
                  field: 'doc::text',
                  type: 'ilike',
                  value: `%` + search + `%`
                }
              ],
              where: 'and'
            }
        )
      } else {
        if (search && search[search.length - 1] !== '*' && search[search.length - 2] !== ':' && !search.includes('%')) {
          search += search[search.length - 1] === ':'
              ? '*'
              : ':*'

          query.search = search
        } else {
          query.search = search
        }
      }

      return this.$service.offer.getAll(query)
          .then(({ page, totalItems, items }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items

            if (totalItems === 0) {
              this.addErrorNotification('Products with that name are not founded!')
            }
          })
    },
    show (options = { filter: null }) {
      this.items = []
      this.pagination = {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      }

      this.onRequest({ pagination: { rowsPerPage: 25, per_page: 25, page: 1 } })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.resolve(null)
    },
    handleItem (item) {
      if (this.options.noSelect) {
        return
      }

      this.hideList = true

      this.$emit('selected', item)
    }
  }
}
</script>
